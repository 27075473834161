<template>
    <div class="flex flex-column">
        <div class="flex">
            <div class="flex-grow-1">
                <h2 class="blue clarity-margin-bottom-m">OTHER INFORMATION</h2>
            </div>
            <div class="flex-none">
                <p class="small-text"><span class="pgCount">3</span> of 3</p>
            </div>
        </div>
        <div v-if="showErrorMessage" class="flex flex-column">
            <div class="create-error-msg">
                <div class="flex justify-content-between">
                    <div class="flex">
                        <h3 class="red"><span class="material-icons">error</span>Missing Information</h3>
                    </div>
                    <div class="flex grey cursor-pointer" @click="closeErrorMessage">
                        <span class="material-icons-outlined">close</span>
                    </div>
                </div>
                <p class="body-text">{{ errorMessage }}</p>
            </div>
        </div>
        <div class="flex">
            <div class="flex-grow-1">
                <h3 class="light-blue clarity-margin-top-m clarity-margin-bottom-s">MARKETING PREFERENCES</h3>
                <MarketingOptions v-model="marketingChoice" />
            </div>
        </div>
        <div class="flex clarity-margin-top-m">
            <div class="flex-grow-1">
                <h3 class="light-blue clarity-margin-bottom-s">TERMS AND CONDITIONS OF USE</h3>

                <div class="tobWrapper">
                    <ScrollPanel style="width: 100%; height: 200px;" class="pr-3">
                    <p class="terms-header">Important information regarding our updated privacy notice</p>
                    <p>In order to provide you with the highest quality advice and services, it is imperative for clarity to fully understand who you are, your personal circumstances and what you want to achieve. As part of the service we provide to you, it is essential for us to collect and maintain certain personal information about you.</p>
                    <p>Keeping your information safe and secure has always been of paramount importance to us. We have ISO ISO27001 accreditation as part of our information security management. To ensure we maintain the highest levels of data security and accuracy, we keep our systems, processes and procedures under continual review in line with General Data Protection Regulation (GDPR).</p>
                    <p>Our privacy notice can be accessed <a href="https://www.clarityglobal.com/newprivacy" target="_blank">here</a> for further reference. The changes don’t alter the ways in which we collect your information, the type of information we hold or what we use it for. Instead, GDPR makes it easier for you to find out how we use and protect your information.</p>
                    <p>Please click the button below to confirm you have read and understood our updated privacy notice and are happy with the way(s) we collect, store and use your personal information.</p>
                    </ScrollPanel>
                </div>

                <div class="flex flex-column pt-3">
                    <div class="pb-3">
                        <Checkbox v-model="termsChecked" inputId="terms" :binary="true"/>
                        <label class="termsLabels" for="terms">Accept Terms & Conditions of Use*</label>
                    </div>
                    <div class="pb-3">
                        <Checkbox v-model="privacyChecked" inputId="privacy" :binary="true"/>
                        <label class="termsLabels" for="privacy">I have read and accept the <a href="https://www.clarityglobal.com/newprivacy" target="_blank">Privacy Terms</a> in full</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-content-between clarity-margin-top-m">
            <Button id="goBackBtn" class="mr-2 mb-2 clarity-gradient-grey clarity-btn" @click="$emit('loadPreviousStage')">
                <span class="material-icons-outlined">chevron_left</span> Go Back
            </Button>
            <submit-button @submitted="saveSelections" :loading="loadingState" button-text="Confirm Details" loading-text="Confirming" ></submit-button>
        </div>
    </div>
</template>

<script>
import {computed, ref} from 'vue';
import {useStore} from 'vuex';
import SubmitButton from "@/components/common/layout/interactive/SubmitButton";
import MarketingOptions from "@/components/settings/MarketingOptions";


export default {
    name:'OnboardingStageThree',
    components: {MarketingOptions, SubmitButton},
    emits: ['completeStageThree'],
    setup(props, context) {
        const store = useStore();
        const termsChecked = ref(false);
        const privacyChecked = ref(false);
        const marketingChoice = ref();
        const loadingState = computed(() => store.getters.isLoading('ConfirmStage3'));
        const showErrorMessage = ref(false);
        const errorMessage = ref('');

        const saveSelections = async () => {
            showErrorMessage.value = false;
            if(termsChecked.value && privacyChecked.value && marketingChoice.value) {
                const stageThreeData = {
                    marketingChoice:marketingChoice.value,
                };
                const data = {data:stageThreeData};
                const result = await store.dispatch('registrationStoreStageThree',data);
                if(result) {
                    store.dispatch('setLoading', {id: 'ConfirmStage3', state: true})
                    context.emit('completeStageThree');
                }
            } else {
                if(!marketingChoice.value){
                    if(!termsChecked.value || !privacyChecked.value){
                        errorMessage.value = 'Please confirm your marketing preferences, and check the Terms & Conditions and Privacy boxes.';
                        showErrorMessage.value = true;
                    } else {
                        errorMessage.value = 'Please confirm your marketing preferences.';
                        showErrorMessage.value = true;
                    }
                } else if(!termsChecked.value || !privacyChecked.value){
                    errorMessage.value = 'Please check the Terms & Conditions and Privacy boxes.';
                    showErrorMessage.value = true;
                }  
            }
        }

        const closeErrorMessage = () => {
            if(showErrorMessage.value) {
                showErrorMessage.value = false;
            }
        }

        return {
            saveSelections,
            closeErrorMessage,
            termsChecked,
            privacyChecked,
            marketingChoice,
            loadingState,
            showErrorMessage,
            errorMessage
        }
    }
}
</script>

<style scoped lang="scss">
.checkbox {
    margin-top: 5px;
}

.termsLabels {
    margin-left: 10px;
    color: #282B2F;
    font-size: 14px;
    vertical-align: top;
}
.emailPrefLabel {
    margin-left: 10px;
    color: #282B2F;
    font-size: 18px;
    vertical-align: top;
}
.pgCount {
    font-weight: bold;
    color: var(--clarity-blue);
}
.readMoreLink {
    text-decoration: underline;
    cursor: pointer;
}
#goBackBtn {
    padding: 15px 45px 15px 35px;
}

.tobWrapper {
    background: var(--clarity-pure-white) 0% 0% no-repeat padding-box;
    border: 1px solid var(--clarity-light-grey);
    border-radius: 27px;
    opacity: 1;
    padding: 30px;
}
.terms-header {
    color: var(--clarity-blue);
    text-align: left;
    font: normal normal bold 14px Open Sans;
}
.create-error-msg {
    background: transparent linear-gradient(180deg, #F2686E33 0%, #D131384D 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 20px #0000000D;
    border: 1px solid #D1313880;
    border-radius: 27px;
    margin-bottom: 30px;
    padding: 32px 30px 35px 30px;
}
.create-error-msg p {
    margin-left: 35px;
    margin-bottom: 0px;
}
.material-icons {
    vertical-align: top;
    margin-right: 10px !important;
}
</style>
