<template>
    <div id="onboardingStages">
        <on-boarding-stage-one @completeStageOne="completeStageOne" v-if="stage==1"></on-boarding-stage-one>
        <on-boarding-stage-two @completeStageTwo="completeStageTwo" @loadPreviousStage="loadPreviousStage" v-if="stage==2"></on-boarding-stage-two>
        <on-boarding-stage-three @completeStageThree="createAccount" @loadPreviousStage="loadPreviousStage" v-if="stage==3"></on-boarding-stage-three>
        <on-boarding-stage-four v-if="stage==4"></on-boarding-stage-four>
    </div>
    <div v-if="signupErrorBox" class="flex flex-column clarity-margin-top-s">
        <div class="create-error-msg">
            <div class="flex justify-content-between">
                <div class="flex">
                    <h3 class="red"><span class="material-icons">error</span>ACCOUNT CREATION FAILED</h3>
                </div>
                <div class="flex grey cursor-pointer" @click="closeSignupError">
                    <span class="material-icons-outlined">close</span>
                </div>
            </div>
            <p class="body-text">Unable to complete registration. Please contact us.</p>
            <p class="body-text">{{ signupErrorMessage }}</p>
        </div>
    </div>
</template>

<script>
import OnBoardingStageOne from '../components/onboarding/OnboardingStageOne.vue';
import OnBoardingStageTwo from '../components/onboarding/OnBoardingStageTwo.vue';
import OnBoardingStageThree from '../components/onboarding/OnBoardingStageThree.vue';
import OnBoardingStageFour from '../components/onboarding/OnBoardingStageFour.vue';
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router'
import { useStore } from 'vuex';
import { Auth } from '@aws-amplify/auth';
import { v4 as uuidv4 } from 'uuid';
import {toClarityAnonRequest} from "../core/helpers";
import api from '@/core/services/api';
// import { toDrupalRequest } from '@/core/helpers';



export default {
    name: 'CreateAccountView',
    components: {OnBoardingStageOne, OnBoardingStageTwo, OnBoardingStageThree, OnBoardingStageFour},
    setup() {
        const store = useStore();
        const router = useRouter();
        const stage = ref(0);
        const signupErrorBox = ref(false);
        const signupErrorMessage = ref('');

        onMounted(() => {
            stage.value = 1;
        });

        const completeStageOne = () => {
            stage.value = 2;
        }

        const completeStageTwo = () => {
            stage.value = 3;
        }

        const createAccount = async () => {
            signupErrorBox.value = false;

            const stageOneData = await store.getters.getStageOneAuthData;
            const stageTwoData = await store.getters.getStageTwoAuthData;
            const stageThreeData = await store.getters.getStageThreeAuthData;

            const d = new Date(stageTwoData.dob);
            const day = d.getDate();
            const month = d.getMonth()+1;
            const year = d.getFullYear();

            const username = stageOneData.email.toLowerCase();
            const password = stageOneData.password;
            const email = username;
            // DoB for cognito
            const birthdate = day.toString().padStart(2,'0') + '/' + month.toString().padStart(2,'0') + '/' + year;
            // DoB for clarity DB tables
            const dob = year + '-' + month.toString().padStart(2,'0') + '-' + day.toString().padStart(2,'0');
            const family_name = stageTwoData.lastName;
            const given_name = stageTwoData.firstName;
            const clarityUUID = uuidv4();

            try {
                const {user} = await Auth.signUp({
                    username,
                    password,
                    attributes: {
                        birthdate,
                        email,
                        family_name,
                        given_name,
                        'custom:clarityUUID':clarityUUID
                    }
                });
                
                if(user) {
                    // D9 function to create clarity specific registration with account set to inactive.
                    const endpoint = 'clientdirectory/newclientregistration';
                    const address = stageTwoData.addrOne + ' ' + stageTwoData.addrTwo + ' ' + stageTwoData.addrThree + ' ' + stageTwoData.addrFour;
                    const userData = {"data":[{
                        "email":email,
                        "date_of_birth":dob,
                        "family_name":family_name,
                        "given_name":given_name,
                        "uuid":clarityUUID,
                        "address":address,
                        "phone":stageTwoData.phone,
                        "marketing_choice":stageThreeData.marketingChoice
                        }
                    ]};

                    console.log(JSON.stringify(userData));
                    
                    const response = await api.post(`${toClarityAnonRequest(endpoint)}`, JSON.stringify({userData}), {
                      headers: {
                        'Content-Type': 'application/json'
                      }
                    })
                    console.log(response);

                    stage.value = 4;
                    store.dispatch('setLoading', {id: 'ConfirmStage3', state: false})
                }
            } catch (err) {
                store.dispatch('setLoading', {id: 'ConfirmStage3', state: false})
                const message = err.message;
                console.log(message);
                signupErrorMessage.value =  message;
                signupErrorBox.value = true;
            }
        }

        const loadNextstage = () => {
            switch(stage.value) {
                case 1:
                    stage.value = 2;
                    break;
                case 2:
                    stage.value = 3;
                    break;
                case 3:
                    createAccount();
                    break;
            }
        }

        const loadPreviousStage = () => {
            switch(stage.value) {
                case 1:
                    router.push('/createaccount');
                    break;
                case 2:
                    stage.value = 1;
                    break;
                case 3:
                    stage.value = 2;
                    break;
            }
        }

        const closeSignupError = () => {
            if(signupErrorBox.value) {
                signupErrorBox.value = false;
            }
        }

        return {
            completeStageOne,
            completeStageTwo,
            loadNextstage,
            loadPreviousStage,
            createAccount,
            closeSignupError,
            stage,
            signupErrorBox,
            signupErrorMessage
        }
    }
}
</script>

<style scoped lang="scss">
    #onboardingStages {
        width: 610px !important;
        margin: 0px !important;
    }
    #passwordStrengthBar {
        background-color: var(--clarity-pure-white);
        height: 12px;
        width: 100%;
        border-radius: 6px;
    }
    .pgCount {
        font-weight: bold;
        color: var(--clarity-blue);
    }
    .create-error-msg {
        background: transparent linear-gradient(180deg, #F2686E33 0%, #D131384D 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 5px 20px #0000000D;
        border: 1px solid #D1313880;
        border-radius: 27px;
        margin-bottom: 30px;
        padding: 32px 30px 35px 30px;
    }
    .create-error-msg p {
        margin-left: 35px;
        margin-bottom: 0px;
    }
    .material-icons {
        vertical-align: top;
        margin-right: 10px !important;
    }
</style>
