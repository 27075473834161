<template>
    <div class="flex flex-column">
        <div class="flex">
            <div class="flex-grow-1">
                <h2 class="blue clarity-margin-bottom-m">CREATE AN ACCOUNT</h2>
            </div>
            <div class="flex-none">
                <p class="small-text"><span class="pgCount">1</span> of 3</p>
            </div>
        </div>
        <div>
            <div class="formgrid grid clarity-margin-bottom-s">
                <div class="field col p-input-icon-left cl-input">
                    <i class="pi cl-email-grey-icon" />
                    <InputText type="text" placeholder="Confirm Email Address..." class="p-inputtext-lg w-full" v-model="confEmail" />
                </div>
            </div>
            <div class="formgrid grid clarity-margin-bottom-xs">
                <div class="field col p-input-icon-left p-input-icon-right cl-input">
                    <i class="pi cl-password-grey-icon" />
                    <InputText :type="hiddenPasswordOne" placeholder="Password..." class="p-inputtext-lg w-full" v-model="passwordOne" @keyup="checkPasswordOne" />
                    <i v-if="hiddenPasswordOne == 'password'" @click="revealPassword(1)" class="pi cl-show-password-grey-icon" />
                    <i v-else @click="revealPassword(1)" class="pi cl-hide-password-grey-icon" />
                </div>
            </div>

            <div :class="{ passwordStrengthBarRed: passwordStrength == 20, passwordStrengthBarDarkOrange: passwordStrength == 40, passwordStrengthBarOrange: passwordStrength == 60, passwordStrengthBarGreen: passwordStrength > 60}">
                <ProgressBar :value="passwordStrength" :showValue="false"></ProgressBar>
            </div>

            <p v-if="passwordStrength != 100" class="small-text blue">To make your password stronger:</p>
            <div class="flex">
                <div class="flex-1">
                    <ul>
                        <li v-if="!pwdLength">Make it at least 8 characters.</li>
                        <li v-if="!pwdLowercase">Add lowercase letters.</li>
                    </ul>
                </div>
                <div class="flex-1">
                    <ul>
                        <li v-if="!pwdUppercase">Add uppercase letters.</li>
                        <li v-if="!pwdNumber">Add numbers.</li>
                    </ul>
                </div>
                <div class="flex-1">
                    <ul>
                        <li v-if="!pwdPunctuation">Add punctuation.</li>
                    </ul>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col p-input-icon-left p-input-icon-right cl-input clarity-margin-top-s">
                    <i class="pi cl-password-grey-icon" />
                    <InputText :type="hiddenPasswordTwo" placeholder="Confirm Password..." class="p-inputtext-lg w-full" v-model="passwordTwo" />
                    <i v-if="hiddenPasswordTwo == 'password'" @click="revealPassword(2)" class="pi cl-show-password-grey-icon" />
                    <i v-else @click="revealPassword(2)" class="pi cl-hide-password-grey-icon" />
                </div>
            </div>
        </div>
        <div class="flex justify-content-between clarity-margin-top-m">
            <Button id="goBackBtn" class="clarity-gradient-grey clarity-btn" @click="loadPreviousStage">
                <span class="material-icons-outlined">chevron_left</span> Go Back
            </Button>
            <Button id="continueBtn" class="clarity-gradient-orange clarity-btn" @click="processStageOne">
                Confirm and Continue <span class="material-icons-outlined">chevron_right</span>
            </Button>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'

export default {
    name:'OnboardingStageOne',
    emits: ['completeStageOne'],
    setup(props, context) {
        const store = useStore();
        const router = useRouter();

        const email = ref();
        const confEmail = ref();
        const passwordOne = ref('');
        const passwordTwo = ref('');
        const passwordStrength = ref(0);
        const pwdLowercase = ref(false);
        const pwdUppercase = ref(false);
        const pwdNumber = ref(false);
        const pwdPunctuation = ref(false);
        const pwdLength = ref(false);
        const hiddenPasswordOne = ref('password');
        const hiddenPasswordTwo = ref('password');

        onMounted(() => {
            email.value = store.getters.getNewUserEmail;
        });

        const loadPreviousStage = () => {
            router.push('/createaccount');
        }

        const checkEmailAddress = () => {
            if(email.value == confEmail.value) {
                return true;
            } else {
                alert('ERROR: Email doesnt match');
                return false;
            }
        }

        const checkPasswordOne = () => {
            const pwd = passwordOne.value;

            if(pwd.match(/[a-z]/g)) {
                pwdLowercase.value = true;
            } else {
                pwdLowercase.value = false;
            }
            if(pwd.match(/[A-Z]/g)) {
                pwdUppercase.value = true;
            } else {
                pwdUppercase.value = false;
            }
            if(pwd.match(/[0-9]/g)) {
                pwdNumber.value = true;
            } else {
                pwdNumber.value = false;
            }
            if(pwd.match(/[\W|_]/g)) {
                pwdPunctuation.value = true;
            } else {
                pwdPunctuation.value = false;
            }
            if(pwd.length > 7) {
                pwdLength.value = true;
            } else {
                pwdLength.value = false;
            }

            passwordStrength.value = 0;
            if(pwdLowercase.value) {
                passwordStrength.value += 20;
            }
            if(pwdUppercase.value) {
                passwordStrength.value += 20;
            }
            if(pwdNumber.value) {
                passwordStrength.value += 20;
            }
            if(pwdPunctuation.value) {
                passwordStrength.value += 20;
            }
            if(pwdLength.value) {
                passwordStrength.value += 20;
            }
        }

        const revealPassword = (elm) => {
            if(elm == 1) {
                if(hiddenPasswordOne.value == 'password') {
                    hiddenPasswordOne.value = 'text';
                } else {
                    hiddenPasswordOne.value = 'password';
                }
            } else if (elm == 2) {
                if(hiddenPasswordTwo.value == 'password') {
                    hiddenPasswordTwo.value = 'text';
                } else {
                    hiddenPasswordTwo.value = 'password';
                }
            }
        }

        const checkPasswordTwo = () => {

            if(passwordOne.value == passwordTwo.value) {
                return true;
            } else {
                alert('ERROR: Passwords dont match');
                return false;
            }
        }

        const processStageOne = async () => {
            const validEmail = checkEmailAddress();
            if(validEmail) {
                const validPassword = checkPasswordTwo();
                if(validPassword) {
                    if(passwordStrength.value > 60) {
                        const stageOneData = {email:confEmail.value,password:passwordTwo.value};
                        const data = {data:stageOneData};
                        const result = await store.dispatch('registrationStoreStageOne',data);
                        if(result) {
                            context.emit('completeStageOne');
                        }
                    } else {
                        alert('ERROR: Password too weak. Please check password guidelines.');
                    }
                }
            }
        }

        return {
            loadPreviousStage,
            checkEmailAddress,
            checkPasswordOne,
            checkPasswordTwo,
            revealPassword,
            processStageOne,
            hiddenPasswordOne,
            hiddenPasswordTwo,
            email,
            confEmail,
            passwordOne,
            passwordTwo,
            passwordStrength,
            pwdLowercase,
            pwdUppercase,
            pwdNumber,
            pwdPunctuation,
            pwdLength
        }
    }
}
</script>

<style scoped lang="scss">
.p-progressbar {
    background-color: var(--clarity-pure-white);
    height: 12px !important;
    margin-bottom: 20px;
}
.pgCount {
    font-weight: bold;
    color: var(--clarity-blue);
}
.small-text {
    margin-bottom: 10px!important;
}
ul {
    margin-top: 0px;
}
.cl-show-password-grey-icon, .cl-hide-password-grey-icon {
    cursor: pointer;
}
#goBackBtn {
    padding: 15px 45px 15px 35px;
}
#continueBtn {
    padding: 15px 35px 15px 45px;
}
</style>
