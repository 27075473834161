<template>
    <div class="flex flex-column">
        <div class="flex">
            <div class="flex-grow-1">
                <h2 class="blue clarity-margin-bottom-m">PERSONAL DETAILS</h2>
            </div>
            <div class="flex-none">
                <p class="small-text"><span class="pgCount">2</span> of 3</p>
            </div>
        </div>
        <div>
            <div v-if="showWarningMsg" class="flex flex-column">
                <div class="warningMessage">
                    <div class="flex justify-content-between">
                        <div class="flex">
                            <h3 class="orange"><span class="material-icons">warning</span>ACTIONS REQUIRED</h3>
                        </div>
                        <div class="flex grey cursor-pointer" @click="closeError">
                            <span class="material-icons-outlined">close</span>
                        </div>
                    </div>

                    <p class="body-text">Please fill in all the mandatory fields:</p>
                    <span class="errMsgText" v-html="warningMsg"></span>
                </div>
            </div>
            <div class="formgrid grid" :class="{ clarityMarginBottomS: !firstNameErr }">
                <div class="field col p-input-icon-right cl-input">
                    <InputText :class="{ errMsgInput: firstNameErr}" type="text" placeholder="First Name*..." class="p-inputtext-lg w-full" v-model="userFirstName" />
                    <i v-if="firstNameErr" class="pi cl-warning-icon" />
                </div>
            </div>
            <p v-if="firstNameErr" class="inputErr">Required Field</p>
            <div class="formgrid grid" :class="{ clarityMarginBottomS: !lastNameErr }">
                <div class="field col p-input-icon-right cl-input">
                    <InputText :class="{ errMsgInput: lastNameErr}" type="text" placeholder="Surname*..." class="p-inputtext-lg w-full" v-model="userLastName" />
                    <i v-if="lastNameErr" class="pi cl-warning-icon" />
                </div>
            </div>
            <p v-if="lastNameErr" class="inputErr">Required Field</p>
            <div class="formgrid grid" :class="{ clarityMarginBottomS: !dobErr }">
                <span class="wrapper">
                    <Calendar :class="{ errMsgDoBInput: dobErr}" placeholder="Date of Birth*..." id="basic" v-model="userDoB" inputId="birth_date" dateFormat="dd/mm/yy" autocomplete="off" @date-select="updateDateOfBirth" @change="updateDateOfBirth"  :max-date="today" ref="datepicker">
                        <template #footer>
                            <div class="flex flex-row-reverse flex-wrap">
                                <!-- <div class="flex align-items-center justify-content-center m-2">
                                    <Button @click="setToToday" class="clarity-btn clarity-gradient-orange">Set As Today</Button>
                                </div> -->
                            </div>
                        </template>
                    </Calendar>
                    <div class="calendar-icon">
                        <span class="material-icons-round grey" :class="{ orange: dobErr }" @click="triggerDatepicker">today</span>
                        <i v-if="dobErr" class="pi cl-warning-icon" style="width:24px;height:24px;vertical-align:top;" />
                    </div>
                </span>
            </div>
            <p v-if="dobErr" class="inputErr">Required Field</p>
            <div class="formgrid grid clarity-margin-bottom-s">
                <div class="field col cl-input">
                    <InputText type="text" placeholder="Phone Number..." class="p-inputtext-lg w-full" v-model="userPhone" />
                </div>
            </div>
            <div v-show="!manualAddress">
                <!-- <div class="formgrid grid clarity-margin-bottom-s">
                    <div class="field col cl-input">
                        <InputText type="text" placeholder="Postcode..." class="p-inputtext-lg w-full" v-model="userPostcode" />
                    </div>
                    <Button @click="findAddress" label="Find Address" class="clarity-gradient-grey clarity-btn" style="height: 50px;"></Button>
                </div> -->
                <div class="formgrid grid" >
                    <div class="field p-input-icon-right col cl-input">
                        <InputText id="userAddress" type="text" placeholder="Address..." class="p-inputtext-lg w-full" v-model="userEnteredAddress" />
                        <i @click="showAddressInfo" class="pi cl-info-icon" />
                    </div>
                    <OverlayPanel ref="op">
                        <div class="flex justify-content-between">
                            <div class="flex">
                                <h3 class="clarity-h3 blue">ADDRESS</h3>
                            </div>
                            <div class="flex grey cursor-pointer" @click="showAddressInfo">
                                <span class="material-icons-outlined">close</span>
                            </div>
                        </div>
                        <p class="body-text">Please type your address here and the<br>input will suggest you the full address.<br>If you don't find your address, please click<br>on the <span class="blue"><b>"Enter Address manually"</b></span> link.</p>
                    </OverlayPanel>
                </div>
                <p @click="toggleManualAddress" class="small-text grey manualAddrTxt">Enter Address manually</p>
            </div>
            <div v-if="manualAddress">
                <div class="formgrid grid clarity-margin-bottom-s" >
                    <div class="field col cl-input">
                        <InputText type="text" placeholder="Address Line 01..." class="p-inputtext-lg w-full" v-model="userAddressOne" />
                    </div>
                </div>
                <div class="formgrid grid clarity-margin-bottom-s" >
                    <div class="field col cl-input">
                        <InputText type="text" placeholder="Address Line 02..." class="p-inputtext-lg w-full" v-model="userAddressTwo" />
                    </div>
                </div>
                <div class="formgrid grid clarity-margin-bottom-s" >
                    <div class="field col cl-input">
                        <InputText type="text" placeholder="Address Line 03..." class="p-inputtext-lg w-full" v-model="userAddressThree" />
                    </div>
                </div>
                <div class="formgrid grid" >
                    <div class="field col cl-input">
                        <InputText type="text" placeholder="Address Line 04..." class="p-inputtext-lg w-full" v-model="userAddressFour" />
                    </div>
                </div>
                <p @click="toggleManualAddress" class="small-text grey manualAddrTxt">Find Address</p>
            </div>
        </div>
        <div class="flex justify-content-between clarity-margin-top-m">
            <Button id="goBackBtn" class="clarity-gradient-grey clarity-btn" @click="$emit('loadPreviousStage')">
                <span class="material-icons-outlined">chevron_left</span> Go Back
            </Button>
            <Button id="continueBtn" class="clarity-gradient-orange clarity-btn" @click="processStageTwo">
                Confirm and Continue <span class="material-icons-outlined">chevron_right</span>
            </Button>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import {useStore} from 'vuex';
import { Loader } from '@googlemaps/js-api-loader';

export default {
    name:'OnboardingStageTwo',
    emits: ['completeStageTwo'],
    components: {},
    setup(props, context) {
        const store = useStore();
        const op = ref();

        const firstNameErr = ref(false);
        const lastNameErr = ref(false);
        const dobErr = ref(false);
        const manualAddress = ref(false);
        const datepicker = ref(null);

        const userFirstName = ref();
        const userLastName = ref();
        const userDoB = ref();
        const userPhone = ref();
        const userAddressOne = ref();
        const userAddressTwo = ref();
        const userAddressThree = ref();
        const userAddressFour = ref();
        //const userPostcode = ref();
        const userEnteredAddress = ref();

        const warningMsg = ref('');
        const showWarningMsg = ref(false);

        onMounted(() => {
            loadMapAPI();
        });

        const triggerDatepicker = () => {
            datepicker.value.onButtonClick();
        }

        const showAddressInfo = (event) => {
            op.value.toggle(event);
        }

        // const findAddress = () => {
        //     console.log('Find Address from postcode');
        // }

        const processStageTwo = async () => {

            showWarningMsg.value = false;
            firstNameErr.value = false;
            lastNameErr.value = false;
            dobErr.value = false;


            var errMsg = '';
            var err = false;
            if(userFirstName.value == undefined) {
                errMsg += '<p style="margin:0px 0px 0px 35px">- First Name</p>';
                err = true;
                firstNameErr.value = true;
            }
            if(userLastName.value == undefined) {
                errMsg += '<p style="margin:0px 0px 0px 35px">- Surname</p>';
                err = true;
                lastNameErr.value = true;
            }
            if(userDoB.value == undefined) {
                errMsg += '<p style="margin:0px 0px 0px 35px">- Date of Birth</p>';
                err = true;
                dobErr.value = true;
            }

            if(!err) {
                const stageTwoData = {
                    firstName:userFirstName.value,
                    lastName:userLastName.value,
                    dob:userDoB.value,
                    phone:userPhone.value,
                    addrOne:userAddressOne.value,
                    addrTwo:userAddressTwo.value,
                    addrThree:userAddressThree.value,
                    addrFour:userAddressFour.value,
                    //addrPostcode:userPostcode.value
                };
                const data = {data:stageTwoData};
                const result = await store.dispatch('registrationStoreStageTwo',data);
                if(result) {
                    context.emit('completeStageTwo');
                }
            } else {
                showWarningMsg.value = true;
                warningMsg.value = errMsg;
            }
        }

        const toggleManualAddress = () => {
            if(manualAddress.value == false) {
                manualAddress.value = true;
            } else {
                manualAddress.value = false;
            }
        }

        const closeError = () => {
            if(showWarningMsg.value) {
                showWarningMsg.value = false;
            }
        }

        async function loadMapAPI() {

            const loader = new Loader({
                apiKey: "AIzaSyA23OTbKRpCSfs3oDg3FgLcUkEgP9kzCPU",
                version: "weekly",
                libraries: ["places"]
            });

            loader
                .load()
                .then(async (google) => {
                    let address1Field = document.querySelector("#userAddress");
                    let fullStreetAddress = '';
                    let autocomplete = new google.maps.places.Autocomplete(address1Field, {
                        componentRestrictions: { country: ["uk"] },
                        fields: ["address_components"],
                        types: ["address"],
                    });
                    address1Field.focus();
                    autocomplete.addListener("place_changed", fillInAddress);

                    function fillInAddress() {
                        const place = autocomplete.getPlace();
                        for (const component of place.address_components) {
                            const componentType = component.types[0];
                            switch (componentType) {
                                case "street_number": {
                                    fullStreetAddress = component.long_name;
                                    break;
                                }
                                case "route": {
                                    fullStreetAddress += ' ' + component.long_name;
                                    break;
                                }
                                case "postal_town": {
                                    userAddressTwo.value = component.long_name;
                                    break;
                                }
                                case "administrative_area_level_2": {
                                    userAddressThree.value = component.long_name;
                                    break;
                                }
                                case "postal_code": {
                                    userAddressFour.value = component.long_name;
                                    break;
                                }
                            }
                        }
                        userAddressOne.value = fullStreetAddress;
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        }

        return {
            datepicker,
            triggerDatepicker,
            processStageTwo,
            toggleManualAddress,
            //findAddress,
            closeError,
            showAddressInfo,
            op,
            userDoB,
            firstNameErr,
            lastNameErr,
            dobErr,
            userEnteredAddress,
            manualAddress,
            userFirstName,
            userLastName,
            userPhone,
            //userPostcode,
            userAddressOne,
            userAddressTwo,
            userAddressThree,
            userAddressFour,
            warningMsg,
            showWarningMsg
        }
    }
}
</script>

<style scoped lang="scss">
.field {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}
.pgCount {
    font-weight: bold;
    color: var(--clarity-blue);
}
.inputErr {
    margin-left: 20px;
    font-size: 14px;
    color: #F18903;
}
.manualAddrTxt {
    text-align: right;
    margin-top: 10px;
    cursor: pointer;
}
.wrapper {
  position: relative;
  width: 100%;
}
.calendar-icon {
  position:absolute;
  top: calc(50% - 12px);
  right: 20px;
}
.p-calendar {
    width: 98% !important;
    margin-left: 7px;
}
.warningMessage {
    background: transparent linear-gradient(180deg, #FFBA6233 0%, #F189034D 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 20px #0000000D;
    border: 1px solid #F1890380;
    border-radius: 27px;
    margin-bottom: 30px;
    padding: 32px 30px 35px 30px;
}
.warningMessage p {
    margin-left: 35px;
    margin-bottom: 0px;
}
.material-icons {
    vertical-align: top;
    margin-right: 10px !important;
}
.errMsgText {
    font-size: 18px;
    font-weight: bold;
    font-style: italic;
}
.errMsgInput {
    border: 1px solid #F18903;
}
.errMsgDoBInput {
    border-radius: 27px;
    border: 1px solid #F18903;
}
.clarityMarginBottomS {
    margin-bottom: 30px;
}
#goBackBtn {
    padding: 15px 45px 15px 35px;
}
#continueBtn {
    padding: 15px 35px 15px 45px;
}
</style>
