<template>
    <div class="flex flex-column">
        <div class="flex">
            <div class="flex-grow-1">
                <h2 class="blue clarity-margin-bottom-m">EMAIL VERIFICATION REQUIRED</h2>
            </div>
        </div>
        <div class="flex">
            <p class="body-text clarity-margin-bottom-s">We've just sent a verification link to:</p>
        </div>
        <div class="flex justify-content-center emailBox clarity-margin-bottom-s">
            <div class="flex align-items-center justify-content-center">
                <p class="userEmail">{{ email }}</p>
            </div>   
        </div>
        <div class="flex">
            <p class="body-text clarity-margin-bottom-m">Please check your inbox and verify by clicking on the link in the email. This link will expire in <span class="blue"><b>24 hours</b></span>.</p>
        </div>
        <div class="flex">
            <span class="grey material-icons securityInfoIcon">error</span><p class="small-text grey">For security reasons, we need to verify your email before you use clarity platform. It is important to have a valid and verified email address to keep your account secure and accessible.</p>
        </div>
        <hr>
        <div class="flex flex-column emailFail clarity-margin-top-m">
            <p class="body-text">Email not arrived?</p>
            <p class="small-text grey">if you have any problems, please send an email to <a href="mailto:enquiries@clarityglobal.com">enquiries@clarityglobal.com</a>.</p>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import {useStore} from 'vuex';

export default {
    name:'OnboardingStageFour',
    setup() {
        const store = useStore();
        const email = ref(null);

        onMounted(async () => {
            const stageOneData = await store.getters.getStageOneAuthData;
            if(stageOneData) {
                email.value = stageOneData.email;
            } else {
                email.value = 'Email Address Error';
            }
            
        });

        return {
            email
        } 
    }
}
</script>
<style scoped lang="scss">
    hr {
        width: 100%;
        height: 1px;
        border-width: 0;
        color: #DCE1E6;
        background-color: #DCE1E6;
    }
    a {
        text-decoration: underline;
        color: var(--clarity-grey);
    }
    .emailBox {
        height: 84px;
        background: var(--clarity-pure-white) 0% 0% no-repeat padding-box;
        border: 1px solid var(--clarity-light-grey);
        border-radius: 27px;
    }
    .userEmail {
        font-size: 18px;
        color: var(--clarity-light-blue);
        margin: 0px;
        text-decoration: underline;
    }
    .emailFail p {
        margin-bottom: 5px !important;
    }
    .securityInfoIcon {
        margin-right: 12px;
    }
</style>